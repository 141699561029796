import { Link } from "gatsby"
import React from "react"
import logo from "../images/english-title.png"
import logoIrish from "../images/irish-title.png"
import play from "../images/play.svg"
import pause from "../images/pause.svg"

function Header({language, englishAudio, irishAudio}) {

  function selectAudio(clickedElement) {
    const englishAudio = document.getElementById("englishAudio")
    const irishAudio = document.getElementById("irishAudio")
    const playButton = document.getElementById("play-button-game")
    const muteButton = document.getElementById("mute-button-game")
    const justSelected = document.getElementById(clickedElement)
  
    //first stop all audio from playing and reset the controls so play button is showing
    englishAudio.pause();
    englishAudio.currentTime = 0;
    irishAudio.pause();
    irishAudio.currentTime = 0;
    muteButton.style.display = "none"
    playButton.style.display = "block"

    //clear selected class
    document.getElementById("irish").classList = ""
    document.getElementById("english").classList =""
  
    //add selected class to current element
    justSelected.classList += "selected"
  }

  function playAudio() {
    const selectedAudio = document.getElementsByClassName("selected")

    for (let selected of selectedAudio) {
      const audioId = selected.id + "Audio"
      console.log(audioId)
      const audioToPlay = document.getElementById(audioId)
      const playButton = document.getElementById("play-button-game")
      const muteButton = document.getElementById("mute-button-game")
      if (audioToPlay.paused) {
        audioToPlay.play() 
        muteButton.style.display = "block"
        playButton.style.display = "none"
      } else {
        audioToPlay.pause()
        muteButton.style.display = "none"
        playButton.style.display = "block"
      }
    }
  }

  return (
    <header>
      <div className="logo-area">
        <Link to="/">
          <img className="logo" src={language==="English" ? logo : logoIrish} alt="Irish Imbas Dark Dawn" />
        </Link>
      </div>
      <div className="audio-area">
        <h2>Audio</h2>
        <div className="all-audio-controls">
          <div className="language-controls">
            <span onClick={() => selectAudio("irish")} id="irish">Gaeilge</span>
            <span onClick={() => selectAudio("english")} className="selected" id="english">English</span>
          </div>
          <audio id="englishAudio" src={englishAudio} type="audio/mpeg" />
          <audio id="irishAudio" src={irishAudio} type="audio/mpeg" />
          <div onClick={playAudio} className="audio-controls">
            <img id="play-button-game" src={play} alt="play button" />
            <img id="mute-button-game" src={pause} alt="pause button" />
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header

