import React from 'react'
import {Link} from 'gatsby'


const Prefooter = () => (
    <div className="pre-footer">
        <Link to="/"><button>Back to the start</button></Link>
        <a target="_blank" href="https://irishimbasbooks.com"><button>Go to Irish Imbas</button></a>
    </div>
)

export default Prefooter